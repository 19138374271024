import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box
} from '@material-ui/core';

import { SwiperImage } from 'components/molecules';
import { Section, Parallax } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: 'white',
    overflow: 'hidden',
  },
  sectionWrapper: {
    height: 400,
    backgroundColor: '#f6f9fa',
  },
  textWhite: {
    color: 'white',
  },
  textBlack: {
    color: 'black',
  },
  title: {
    fontWeight: 'bold',
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    height: 99,
    width: 99,
  },
}));

const Slideshow = props => {
  
  const { className, cover, title, subtitle, author, ...rest } = props;
  const classes = useStyles();
  
  return (
    <Box display="flex" justifyContent="space-evenly" alignItems="center">
      <SwiperImage
        style={{
            width: "100%",
            height: "700px",
        }}
        items={[{
          src: require('assets/images/location/derbarber/derbarber1.jpg'),
          srcSet: require('assets/images/location/derbarber/derbarber1@2x.jpg'),
          alt: 'Innenbereich',
        },
        {
          src: require('assets/images/location/derbarber/derbarber2.jpg'),
          srcSet: require('assets/images/location/derbarber/derbarber2@2x.jpg'),
          alt: 'Schaufenster',
        },
        {
          src: require('assets/images/location/derbarber/derbarber3.jpg'),
          srcSet: require('assets/images/location/derbarber/derbarber3@2x.jpg'),
          alt: 'Schaufenster',
        },
        {
          src: require('assets/images/location/derbarber/derbarber4.jpg'),
          srcSet: require('assets/images/location/derbarber/derbarber4@2x.jpg'),
          alt: 'Schaufenster',
        },
        {
          src: require('assets/images/location/derbarber/derbarber5.jpg'),
          srcSet: require('assets/images/location/derbarber/derbarber5@2x.jpg'),
          alt: 'Innenbereich',
        }]}
      />
    </Box>
  );
};

Slideshow.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Cover of the Slideshow
   */
  cover: PropTypes.object.isRequired,
  /**
   * Title of the Slideshow
   */
  title: PropTypes.string.isRequired,
  /**
   * Subtitle of the Slideshow
   */
  subtitle: PropTypes.string.isRequired,
  /**
   * Author of the post
   */
  author: PropTypes.object.isRequired,
};

export default Slideshow;
