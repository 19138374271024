import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Chip,
  useMediaQuery
} from '@material-ui/core';

import TvIcon from '@material-ui/icons/Tv';
import AspectRatio from '@material-ui/icons/AspectRatio';

import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  listItemAvatar: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    height: 99,
    width: 99,
  },
}));


const LocationIntro = props => {
  
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const justifyResponsive = isMd ? "flex-end" : "flex-start";
  
  const { className, cover, title, subtitle, address, author, ...rest } = props;
  const classes = useStyles();
  
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      
        <div>
      
          <Section className={classes.section}>
            <>

            <Grid container>

              {/* Titel / Untertitel */}
              <Grid item xs="12" sm="12" md="6">
                <SectionHeader
                  title={title}
                  subtitle={subtitle}
                  align="left"
                  data-aos="fade-up"
                  titleProps={{
                    variant: 'h3',
                  }}
                />
              </Grid>
              
              {/* Highlights */}
              <Grid item xs="12" sm="12" md="6">

                {/* 1. Highlights */}
                <Grid container spacing="2" style={{marginTop:2}} justify={justifyResponsive} >
                  <Grid item md="auto">
                    <Chip color="secondary" label="Innenbereich" />
                  </Grid>
                  <Grid item md="auto">
                    <Chip variant="outlined" icon={<TvIcon />} label="2x"/>
                  </Grid>
                  <Grid item md="auto">
                    <Chip variant="outlined" color="" icon={<AspectRatio />} label="58"/>
                  </Grid>
                </Grid>

                {/* 2. Highlights */}
                <Grid container spacing="2" style={{marginTop:10}} justify={justifyResponsive} >
                  <Grid item md="auto">
                    <Chip color="secondary" label="Schaufenster" />
                  </Grid>
                  <Grid item md="auto">
                    <Chip color="primary" label="Premium" />
                  </Grid>
                  <Grid item md="auto">
                    <Chip variant="outlined" color="" icon={<TvIcon />} label="1x"/>
                  </Grid>
                  <Grid item md="auto">
                    <Chip variant="outlined" color="" icon={<AspectRatio />} label="40"/>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>

            </>
          </Section>

        </div>
    </div>
  );
};

LocationIntro.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Cover of the LocationIntro
   */
  cover: PropTypes.object.isRequired,
  /**
   * Title of the LocationIntro
   */
  title: PropTypes.string.isRequired,
  /**
   * Subtitle of the LocationIntro
   */
  subtitle: PropTypes.string.isRequired,
  /**
   * Author of the post
   */
  author: PropTypes.object.isRequired,
};

export default LocationIntro;
