export const content = {
  title: 'Der Barber',
  subtitle:
    'in der Marktpassage Siegburg',
  address:
    'Markt 16, 53721 Siegburg',

  headline:
    '„Der Barber“ ist seit 2016 in der Marktpassage und gleichzeitig der rennomierteste Herren-Friseursalon in Siegburg. Mit 28 tausend Instagram-Followern lässt sich die Bekanntheit von „Der Barber“ unterstreichen.',
  quote:
    "Keep track of what's happening with your data, change permissions, against your data anywhere in the world.",
  text1:
    'Barbecue party tips can help a host put together an extraordinary event for family and friends. Eating outdoors is a favorite pastime enjoyed by everyone and planning the event can help make it more memorable and stress-free for the host. Big or small, a barbecue party requires an adequate amount of planning and preparation to ensure that everything is laid out properly.',
  text2:
    'Barbecue party tips can help a host put together an extraordinary event for family and friends. Eating outdoors is a favorite pastime enjoyed by everyone and planning the event can help make it more memorable and stress-free for the host. Big or small, a barbecue party requires an adequate amount of planning and preparation to ensure that everything is laid out properly.',
  author: {
    photo: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    name: 'Jack Smith',
    date: 'May 20, 2019',
  },
  cover: {
    src: require('assets/images/location/derbarber/derbarber.jpeg'),
    srcSet: require('assets/images/location/derbarber/derbarber.jpeg'),
  },
  images: [
    {
      src: require('assets/images/location/derbarber/derbarber1.jpg'),
      srcSet: require('assets/images/location/derbarber/derbarber1@2x.jpg'),
      cols: 2,
    },
    {
      src: require('assets/images/location/derbarber/derbarber2.jpg'),
      srcSet: require('assets/images/location/derbarber/derbarber2@2x.jpg'),
      cols: 2,
    },
    {
      src: require('assets/images/location/derbarber/derbarber3.jpg'),
      srcSet: require('assets/images/location/derbarber/derbarber3@2x.jpg'),
      alt: 'Schaufenster',
    },
    {
      src: require('assets/images/location/derbarber/derbarber4.jpg'),
      srcSet: require('assets/images/location/derbarber/derbarber4@2x.jpg'),
      cols: 2,
    },
    {
      src: require('assets/images/location/derbarber/derbarber5.jpg'),
      srcSet: require('assets/images/location/derbarber/derbarber5@2x.jpg'),
      cols: 2,
    }
    /*
    {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover4.jpg 2x',
      cols: 2,
    },
    {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover3.jpg 2x',
      cols: 1,
    },
    {
      src: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/blog/cover1.jpg 2x',
      cols: 1,
    },*/
  ],
};

export const mapData = [
  {
    location: {
      y: 50.797576,
      x: 7.2075563,
      address: 'Der Barber Traditionshandwerk in Siegburg',
    },
  }
];