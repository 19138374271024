import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
  Typography, 
  Grid,
  useMediaQuery
} from '@material-ui/core';
import { Image, IconText } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  image: {
    objectFit: 'cover',
    borderRadius: theme.spacing(1),
  },
  icontext: {
    color: theme.palette.primary.main
  }
}));


const Content = props => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  });
  
  const SendezeitenGridMarginBottom = isXs ? -45 : 0;
  const SendezeitenGridMarginTop = isXs ? 15 : 0;
  const InnenGridMarginBottom = isXs ? -30 : -55;
  const ZielgruppeGridMarginTop = isXs ? 25 : 30;

  const { data, className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={className} {...rest}>
      
      {/* Zur Location */}
      <Grid container spacing={6}>
      
        <Grid item md="6">    
          <div className={classes.section}>
            <Typography component="p" variant="h3" color="textPrimary">
              Zur Location
            </Typography>
          </div>
          <div className={classes.section}>
            <Typography component="p" color="textPrimary">
              {data.headline}
            </Typography>
          </div>
        </Grid>

        <Grid item md="6" style={{marginTop: -40}}>
          <div className={classes.section}>
            <Image
              {...data.cover}
              className={classes.image}
              lazyProps={{ width: '100%', height: '100%' }}
              />
          </div>
        </Grid>

      </Grid>

      {/* Sendezeiten */}
      <Grid container spacing={6} style={{marginBottom: SendezeitenGridMarginBottom, marginTop: SendezeitenGridMarginTop }}>
        <Grid item md="12">    
          <Typography component="p" variant="h3" color="textPrimary">
            Sendezeiten
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={6}>

        <Grid item md="12" style={{marginBottom: InnenGridMarginBottom, paddingTop: "45px"}}>

          <Grid container>

            <Grid item md="3" xs="12">    
              <div className={classes.section}>
                <Typography component="p" variant="h4" color="textPrimary">
                  Innen
                </Typography>
              </div>
            </Grid>

            <Grid item md="3" xs="6">    
              <div className={classes.section}>
                <Typography component="p" variant="h6" color="textPrimary">
                  Dienstag – Mittwoch<br />10 – 19 Uhr
                </Typography>
              </div>
            </Grid>

            <Grid item md="3" xs="6">    
              <div className={classes.section}>
                <Typography component="p" variant="h6" color="textPrimary">
                  Donnerstag – Freitag<br />10 – 20 Uhr
                </Typography>
              </div>
            </Grid>

            <Grid item md="3" xs="6">    
              <div className={classes.section}>
                <Typography component="p" variant="h6" color="textPrimary">
                  Samstag<br />9 – 15 Uhr
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md="12">

          <Grid container>

            <Grid item md="3" xs="12">
              <div className={classes.section}>
                <Typography component="p" variant="h4" color="textPrimary">
                  Schaufenster
                </Typography>
              </div>
            </Grid>

            <Grid item md="3" xs="12">
              <div className={classes.section}>
                <Typography component="p" variant="h6" color="textPrimary">
                  Täglich, 365 Tage<br />24 Stunden
                </Typography>
              </div>
            </Grid>

          </Grid>
        </Grid>
      </Grid>

      {/* Zielgruppe / Umfeld */}
      <Grid container spacing={6} style={{marginTop: ZielgruppeGridMarginTop}}>
      
        <Grid item sm="6" md="6">
          <div className={classes.section}>
            <Typography component="p" variant="h3" color="textPrimary">
              Zielgruppen
            </Typography>
          </div>
          <div className={classes.section}>
            <IconText fontIconClass="fas fa-users" className={classes.icontext} title="Familien" typographyProps={{variant:"h6"}}/>
            <IconText fontIconClass="fas fa-briefcase" className={classes.icontext} style={{marginLeft:4}}title="Geschäftsleute" typographyProps={{variant:"h6"}}/>
            <IconText fontIconClass="fas fa-male" className={classes.icontext} style={{marginLeft:15}} title="Senioren" typographyProps={{variant:"h6"}}/>
            <IconText fontIconClass="fas fa-route" className={classes.icontext} style={{marginLeft:2}} title="Touristen" typographyProps={{variant:"h6"}}/>
          </div>
        </Grid>

        <Grid item sm="6" md="6">
          <div className={classes.section}>
            <Typography component="p" variant="h3" color="textPrimary">
              Umfeld
            </Typography>
          </div>
          <div className={classes.section}>
            <IconText fontIconClass="fas fa-shopping-bag" className={classes.icontext} style={{marginLeft:8}} title="zentrale Shopping-Meile" typographyProps={{variant:"h6"}}/>
            <IconText fontIconClass="fas fa-coffee" className={classes.icontext} title="zwei gut besuchte Cafés" typographyProps={{variant:"h6"}}/>
            <IconText fontIconClass="fas fa-walking" className={classes.icontext} style={{marginLeft:12}} title="Fußgängerzone Marktpassage" typographyProps={{variant:"h6"}}/>
          </div>
        </Grid>
        
      </Grid>

      {/* Kosten */}
      <Grid container spacing={6} style={{marginTop: ZielgruppeGridMarginTop}}>

        <Grid item sm="12" md="6">
          <div className={classes.section}>
            <Typography component="p" variant="h3" color="textPrimary">
              Kosten
            </Typography>
            <Typography component="p" variant="h6" color="textPrimary">
              Pay-per-Count
            </Typography>
          </div>
          <div className={classes.section}>
            <Typography component="p" color="textPrimary">
              Sie kennen Ihr Unternehmen, also bestimmen Sie das Budget. Sie zahlen nie mehr als den für das Monatsbudget festgelegten Höchstbetrag.
            </Typography>
            <div className={classes.section}></div>
            <Typography component="p" color="textPrimary">
              Der Preis pro Sichtkontakt beträgt 3 Cent / 0,03€. Das heißt, 100 Sichtkontakte bekommen Sie schon für 3€. Ein anderes Beispiel: Bei einem Sichtkontakt-Preis von 3 Cent würden 10k Sichtkontakte 300€ kosten.
            </Typography>
            <div className={classes.section}></div>
            <Typography component="p" color="textPrimary">
              Die Kosten entstehen nur (und wirklich nur dann), wenn unser Personenzählsystem Menschen gezählt hat, während Ihr Werbespot lief (Per-per-Count). Durch diese Zahlvariante / Messung haben Sie eine Sicherheit bezüglich den Impressionen und eine Relation zu den Kosten (und zahlen nichts pauschal im Voraus). 
            </Typography>
          </div>
        </Grid>
        
      </Grid>
    
    </div>
  );
};

Content.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.object.isRequired,
};

export default Content;
