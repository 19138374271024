import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Section, SectionAlternate } from 'components/organisms';
import {
  LocationIntro,
  Slideshow,
  Content,
  Application,
  Map
} from './components';

import { content, mapData } from './data';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  }
}));

const Location = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <LocationIntro
        cover={content.cover}
        title={content.title}
        subtitle={content.subtitle}
        address={content.address}
        author={content.author}
      />
      <Slideshow />
      <Section>
        <Content data={content} />
      </Section>
      <Map data={mapData} />
      <SectionAlternate innerNarrowed>
        <Application />
      </SectionAlternate>

    </div>
  )
}

export default Location;
